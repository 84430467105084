import React, { Component } from "react";
import Layout from "../components/layout";
import {
  Breadcrumbs,
  Post,
  Button,
} from "../components/styleguide";
import Gallery from "../components/gallery";
import { Player, BigPlayButton } from "video-react";
import MarkdownRenderer from "react-markdown-renderer";
import { Link } from "gatsby";
import { createSlug } from "../../utils/constants.js";
import ReactHtmlParser from "react-html-parser";
import Carousel, {
  Modal,
  ModalGateway,
} from "react-images";

const HeaderClose = ({ innerProps }) => (
  <button {...innerProps} className="gallery-close">
    <img
      className="lazyload"
      data-src={require("../images/icons/gallery_close.svg")}
      alt="Close icon"
    />
  </button>
);
const NavigationPrev = ({ innerProps }) => (
  <button {...innerProps} className="gallery-arrow prev">
    <img
      className="lazyload"
      data-src={require("../images/icons/slick-prev.svg")}
      alt="Prev arrow"
    />
  </button>
);
const NavigationNext = ({ innerProps }) => (
  <button {...innerProps} className="gallery-arrow next">
    <img
      className="lazyload"
      data-src={require("../images/icons/slick-next.svg")}
      alt="Next arrow"
    />
  </button>
);

class PostPage extends Component {
  state = {
    descImages: [],
    selectedIndex: 0,
    modalIsOpen: false,
  };
  toggleModal = (selectedIndex) => {
    const { modalIsOpen } = this.state;
    this.setState({
      modalIsOpen: !modalIsOpen,
      selectedIndex,
    });
  };
  componentDidMount() {
    const mainDescContainer =
      document.getElementById("imagesWrapper");
    if (!mainDescContainer) return false;
    const images = Array.from(
      mainDescContainer.getElementsByTagName("img")
    );
    if (images.length < 1) return false;
    const descImages = images
      .filter(
        (element) => element.parentNode.tagName !== "A"
      )
      .map((image, index) => ({
        src: image.src,
        title: "",
        index,
      }));

    images
      .filter(
        (element) => element.parentNode.tagName !== "A"
      )
      .forEach((element, index) => {
        element.style.cursor = "pointer";
        element.addEventListener("click", () =>
          this.toggleModal(index)
        );
      });
    this.setState({
      descImages,
    });
  }
  transformHtml() {
    const { data, common } = this.props.pageContext;
    const html = ReactHtmlParser(data.content);
    const transformedHtml = html.map((node) => {
      if (node && node.type === "iframe") {
        const { children, src, ...rest } = node.props;
        return (
          <React.Fragment key={node.key}>
            {common.lang === "pl" ? (
              <div className="cookieconsent-optout-marketing">
                Proszę{" "}
                <a href="javascript:Cookiebot.renew()">
                  zaakceptować marketingowe pliki cookies
                  klikając tutaj
                </a>
                , aby obejrzeć ten film.
              </div>
            ) : (
              <div className="cookieconsent-optout-marketing">
                Please{" "}
                <a href="javascript:Cookiebot.renew()">
                  accept marketing-cookies by clicking here
                </a>
                , to watch this video.
              </div>
            )}
            <iframe
              data-src={src}
              data-cookieconsent="marketing"
              {...rest}
            />
          </React.Fragment>
        );
      }
      if (
        node &&
        node.type === "article" &&
        node.props.children &&
        node.props.children.length > 0 &&
        node.props.children[0].type === "iframe"
      ) {
        const { children, src, ...rest } =
          node.props.children[0].props;
        return (
          <article
            key={node.key}
            style={{ display: "block" }}
          >
            {common.lang === "pl" ? (
              <div className="cookieconsent-optout-marketing">
                Proszę{" "}
                <a href="javascript:Cookiebot.renew()">
                  zaakceptować marketingowe pliki cookies
                  klikając tutaj
                </a>
                , aby obejrzeć ten film.
              </div>
            ) : (
              <div className="cookieconsent-optout-marketing">
                Please{" "}
                <a href="javascript:Cookiebot.renew()">
                  accept marketing-cookies by clicking here
                </a>
                , to watch this video.
              </div>
            )}
            <iframe
              data-src={src}
              data-cookieconsent="marketing"
              {...rest}
            />
          </article>
        );
      }

      return node;
    });
    return transformedHtml;
  }
  render() {
    const {
      data,
      common,
      breadcrumbs,
      blogPath,
      relatedArticles,
    } = this.props.pageContext;
    const { descImages, selectedIndex, modalIsOpen } =
      this.state;
    return (
      <>
        <ModalGateway>
          {modalIsOpen ? (
            <Modal
              onClose={this.toggleModal}
              allowFullscreen={false}
              styles={{
                blanket: (base) => ({
                  ...base,
                  zIndex: 100,
                  background: "rgba(255,255,255,.8)",
                }),
                positioner: (base) => ({
                  ...base,
                  zIndex: 100,
                }),
              }}
            >
              <Carousel
                views={descImages}
                currentIndex={selectedIndex}
                components={{
                  NavigationPrev,
                  NavigationNext,
                  HeaderClose,
                }}
                selectedIndex={selectedIndex}
                hideControlsWhenIdle={false}
                styles={{
                  footer: (base) => ({
                    ...base,
                    display: "none",
                  }),
                  header: (base) => ({
                    ...base,
                    background: "transparent!important",
                    maxWidth: "1200px",
                    padding: "25px 10px",
                    margin: "0 auto",
                  }),
                  container: (base) => ({
                    ...base,
                    maxWidth: "1080px",
                  }),
                  navigationItem: (base) => ({
                    ...base,
                    backgroundColor: "transparent",
                    zIndex: "100",
                  }),
                }}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        <Layout
          data={common}
          title={`${data.seoTitle} | DMV_PLUS`}
          description={
            data.content && data.content.length > 0
              ? data.content
                  .replace(/<[^>]*>?/gm, "")
                  .split("")
                  .filter(
                    (item) => item !== "\n" && item !== ""
                  )
                  .splice(0, 140)
                  .join("")
              : data.seoDescription
          }
          hrefLang={data.hrefLang}
        >
          <div className="main-container">
            <Breadcrumbs
              data={breadcrumbs}
              lang={common.lang}
            />
            <header className="section-header">
              <h1>
                <MarkdownRenderer markdown={data.title} />
              </h1>
            </header>
            <section className="post-container">
              <div className="tags-list">
                {data.tag_post_relation.map(
                  ({ name, id }, index) => (
                    <Link
                      key={id}
                      className="tag font-ms"
                      to={`${blogPath}/${createSlug(name)}`}
                    >
                      #{name}
                      {index !==
                      data.tag_post_relation.length - 1
                        ? ","
                        : ""}
                    </Link>
                  )
                )}
              </div>
              <div className="post-container--wrapper">
                <div className="post-content">
                  <div className="box">
                    <div
                      id="imagesWrapper"
                      className="markdown-content"
                    >
                      {this.transformHtml()}
                    </div>

                    {data.video && (
                      <Player playsInline src={data.video}>
                        <BigPlayButton position="center" />
                      </Player>
                    )}
                  </div>
                  {data.gallery &&
                    data.gallery.length > 0 && (
                      <div className="box post-gallery">
                        <Gallery
                          images={data.gallery.map(
                            ({ title, image }) => ({
                              src: image,
                              title,
                            })
                          )}
                        />
                      </div>
                    )}
                  <div className="button-holder">
                    <Button
                      type="link"
                      href={blogPath}
                      fill
                      content={
                        common.translations[0]
                          .go_to_articles
                      }
                      size="lg"
                    />
                  </div>
                </div>
                <aside className="post-aside-panel">
                  <h3>
                    <strong>Zobacz</strong> również
                  </h3>
                  <div className="post-wrapper">
                    {relatedArticles.map((item) => (
                      <Post
                        key={item.id}
                        data={item}
                        blogPath={common.blogLink}
                      />
                    ))}
                  </div>
                </aside>
              </div>
            </section>
          </div>
        </Layout>
      </>
    );
  }
}

export default PostPage;
